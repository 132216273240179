
import Component from "vue-class-component";
import Vue from "vue";
import { AssetClass, StrategyRun, StrategyRunListFilters, StrategyRunSummary } from "next-trader-api";
import { assetClassApi, strategyRunApi } from "@/plugins/api";
import { map } from "rxjs/operators";
import { mdiAutoFix, mdiChartBox, mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { Prop } from "vue-property-decorator";
import { keyBy } from "lodash-es";
import ProfitPercent from "@/components/ProfitPercent.vue";
import StrategyRunGroupSummaryChart from "@/components/StrategyRunGroup/StrategyRunGroupSummaryChart.vue";
import StrategyRunCreate from "@/components/StrategyRunCreate.vue";
import StrategyRunGroupFilters from "@/components/StrategyRunGroup/StrategyRunGroupFilters.vue";
@Component({
  components: {
    StrategyRunGroupFilters,
    StrategyRunCreate,
    StrategyRunGroupSummaryChart,
    ProfitPercent,
  },
})
export default class StrategyRunList extends Vue {
  @Prop({ default: 25 })
  itemPerPage?: number;

  strategyRuns: Array<StrategyRun> = [];
  filters: StrategyRunListFilters = {
    minimumPositionCount: null,
  };

  assetClasses: Array<AssetClass> = [];

  isGroup = false;
  isNewFormOpen = true;

  icons = {
    mdiAutoFix: mdiAutoFix,
    mdiChartBox: mdiChartBox,
    mdiChevronUp: mdiChevronUp,
    mdiChevronDown: mdiChevronDown,
  };

  getStrategyRunGet$() {
    const strategyRunGroupId = this.$router.currentRoute.params.strategyRunGroupId;

    return strategyRunApi
      .postAppStrategyrunList({
        strategyRunListRequest: {
          strategyRunGroupId: strategyRunGroupId ? parseInt(strategyRunGroupId) : undefined,
          filters: this.filters,
        },
      })
      .pipe(
        map((next: Array<StrategyRun>) => {
          for (const strategyRun of next) {
            strategyRun.strategyRunSummariesYearlyIndexed = keyBy(strategyRun.strategyRunSummariesYearly, "periodName");
          }
          this.strategyRuns = next;
          return next;
        })
      );
  }

  getAssetClassGet$() {
    return assetClassApi.getAppAssetclassGetall().pipe(
      map((next: Array<AssetClass>) => {
        this.assetClasses = next;
        return next;
      })
    );
  }

  created() {
    this.getStrategyRunGet$().subscribe();
    this.getAssetClassGet$().subscribe();

    this.isGroup = this.$router.currentRoute.params.strategyRunGroupId != undefined;
  }

  headers: Array<{ text: string; value: string }> = [
    { text: "Started at", value: "startedAt" },
    { text: "Asset class", value: "assetClass" },
    { text: "Markets", value: "markets" },
    { text: "Net profit", value: "netProfit" },
    { text: "Strategy", value: "strategy.name" },
    { text: "2017", value: "profit2017" },
    { text: "2018", value: "profit2018" },
    { text: "2019", value: "profit2019" },
    { text: "2020", value: "profit2020" },
  ];

  onStrategyRunCreated() {
    this.getStrategyRunGet$().subscribe();
  }

  onFilter() {
    this.getStrategyRunGet$().subscribe();
  }

  groupSummaryNetProfitPercent() {
    let sumNetProfit = 0;
    let count = 0;
    const initialCapital = 100000; //TODO
    for (const strategyRun of this.strategyRuns || []) {
      const netProfit = strategyRun.strategyRunSummaryAll?.netProfit || 0;
      sumNetProfit += netProfit;
      count++;
    }

    const sumCapital = count * initialCapital;
    const actualSumCapital = count * initialCapital + sumNetProfit;

    return (actualSumCapital / sumCapital - 1) * 100;
  }

  getNetProfitPercent(strategyRunSummary: StrategyRunSummary) {
    return strategyRunSummary ? strategyRunSummary.netProfitPercent : undefined;
  }

  getSortBy() {
    if (this.isGroup) {
      return ["netProfit"]; //TODO Sort by netProfit doesn't work, because it's a calculated property. Make it work somehow!
    } else {
      return ["startedAt"];
    }
  }

  getSortDesc() {
    return [true];
  }
}
