
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { StrategyRun } from "api/models";
@Component
export default class StrategyRunGroupSummaryChart extends Vue {
  @Prop()
  strategyRuns?: Array<StrategyRun>;

  created() {
    const positiveValues: Record<string, number> = {
      "0-5": 0,
      "5-10": 0,
      "10-20": 0,
      "20-30": 0,
      "30-40": 0,
      "40-50": 0,
      "50-100": 0,
      ">100": 0,
    };

    const negativeValues: Record<string, number> = {
      "0-5": 0,
      "5-10": 0,
      "10-20": 0,
      "20-30": 0,
      "30-40": 0,
      "40-50": 0,
      "50-100": 0,
      ">100": 0,
    };

    for (const strategyRun of this.strategyRuns || []) {
      const netProfitPercent = strategyRun.strategyRunSummaryAll?.netProfitPercent || 0;
      const netProfit = strategyRun.strategyRunSummaryAll?.netProfit || 0;

      if (netProfitPercent >= 0 && netProfitPercent <= 5) {
        positiveValues["0-5"] += netProfit;
      }
      if (netProfitPercent > 5 && netProfitPercent <= 10) {
        positiveValues["5-10"] += netProfit;
      }
      if (netProfitPercent > 10 && netProfitPercent <= 20) {
        positiveValues["10-20"] += netProfit;
      }
      if (netProfitPercent > 20 && netProfitPercent <= 30) {
        positiveValues["20-30"] += netProfit;
      }
      if (netProfitPercent > 30 && netProfitPercent <= 40) {
        positiveValues["30-40"] += netProfit;
      }
      if (netProfitPercent > 40 && netProfitPercent <= 50) {
        positiveValues["40-50"] += netProfit;
      }
      if (netProfitPercent > 50 && netProfitPercent <= 100) {
        positiveValues["50-100"] += netProfit;
      }
      if (netProfitPercent > 100) {
        positiveValues[">100"] += netProfit;
      }
      if (netProfitPercent < 0 && netProfitPercent >= -5) {
        negativeValues["0-5"] += netProfit;
      }
      if (netProfitPercent < -5 && netProfitPercent >= -10) {
        negativeValues["5-10"] += netProfit;
      }
      if (netProfitPercent < -10 && netProfitPercent >= -20) {
        negativeValues["10-20"] += netProfit;
      }
      if (netProfitPercent < -20 && netProfitPercent >= -30) {
        negativeValues["20-30"] += netProfit;
      }
      if (netProfitPercent < -30 && netProfitPercent >= -40) {
        negativeValues["30-40"] += netProfit;
      }
      if (netProfitPercent < -40 && netProfitPercent >= -50) {
        negativeValues["40-50"] += netProfit;
      }
      if (netProfitPercent < -50 && netProfitPercent >= -100) {
        negativeValues["50-100"] += netProfit;
      }
      if (netProfitPercent < -100) {
        negativeValues[">100"] += netProfit;
      }
    }

    this.options.series[0].data = Object.values(positiveValues);
    this.options.series[1].data = Object.values(negativeValues);
  }

  private options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      top: "20",
      bottom: "20",
      containLabel: true,
    },
    xAxis: [
      {
        type: "value",
        show: false,
      },
    ],
    yAxis: [
      {
        type: "category",
        axisTick: {
          show: false,
        },
        position: "right",
        data: ["5%", "10%", "20%", "30%", "40%", "50%", "100%", "more"],
      },
    ],
    series: [
      {
        name: "Profit",
        type: "bar",
        stack: "stack1",
        label: {
          //show: true
        },
        itemStyle: {
          color: "green",
        },
        data: [],
      },
      {
        name: "Loss",
        type: "bar",
        stack: "stack1",
        label: {
          //show: true
          //position: "left"
        },
        itemStyle: {
          color: "#d81a1a",
        },
        data: [],
      },
    ],
  };
}
