
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { AssetClass, StrategyRunListFilters } from "api/models";
@Component
export default class StrategyRunGroupFilters extends Vue {
  @Prop()
  filters?: StrategyRunListFilters;

  @Prop()
  assetClasses?: Array<AssetClass>;

  onFilter() {
    this.$emit("filter");
  }
}
