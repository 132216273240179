
import Component from "vue-class-component";
import Vue from "vue";
import { marketApi, sourceApi, strategyApi, strategyRunApi } from "@/plugins/api";
import { Market, Source, Strategy, StrategyRunExecuteRequest } from "api/models";
import { mdiCalendar, mdiPlay, mdiTrendingUp, mdiWindowClose } from "@mdi/js";
@Component
export default class StrategyRunCreate extends Vue {
  valid = true;

  markets: Array<Market> = [];
  sources: Array<Source> = [];
  strategies: Array<Strategy> = [];
  exitStrategies: Array<Strategy> = [];

  snackbar = false;
  snackbarText = "";

  menuTo = false;
  menuFrom = false;

  icons = {
    mdiTrendingUp: mdiTrendingUp,
    mdiCalendar: mdiCalendar,
    mdiPlay: mdiPlay,
    mdiWindowClose: mdiWindowClose,
  };

  params: StrategyRunExecuteRequest = {
    strategyInitialBalance: { exchangeId: 0, currencyId: 0, value: 10000 },
    marketIds: [9],
    from: "2021-01-01",
    to: "2022-01-01",
    strategyId: 8403,
    exitStrategyId: 416,
    entryStrategyParams: "40,10,8,4,20",
    exitStrategyParams: "0.06,0.01,500",
    candleSize: "5s",
    saveOnlySummary: false,
  };

  created() {
    marketApi.getAppMarketGetall().subscribe((markets: Array<Market>) => {
      this.markets = markets;
    });
    sourceApi.getAppSourceGetall().subscribe((sources: Array<Source>) => {
      this.sources = sources;
    });
    strategyApi.getAppStrategyGetall().subscribe((strategies: Array<Strategy>) => {
      this.strategies = strategies.filter((item) => item.type === "entry");
      this.exitStrategies = strategies.filter((item) => item.type === "exit");
    });
  }

  runStrategy() {
    this.snackbarText = "Strategy is running...";
    this.snackbar = true;

    strategyRunApi.postAppStrategyrunCreate({ strategyRunExecuteRequest: this.params }).subscribe(() => {
      this.$emit("created");
      this.snackbarText = "Strategy is done!";
      this.snackbar = true;
    });
  }
}
